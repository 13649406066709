$tablet-retina: "only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)";
$tablet-retina-desktop: "only screen and (min-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)";

@font-face {
  font-family: 'Play';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/Play-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Play';
  font-style: normal;
  font-weight: 700;
  src: url(/assets/fonts/Play-Bold.woff2) format('woff2');
}

@font-face {
    font-family: 'YanoneKaffeesatz';
    font-style: normal;
    font-weight: normal;
    src: url(/assets/fonts/YanoneKaffeesatz-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'YanoneKaffeesatz';
    font-style: normal;
    font-weight: bold;
    src: url(/assets/fonts/YanoneKaffeesatz-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Steelfish';
    font-style: normal;
    font-weight: normal;
    src: url(/assets/fonts/Steelfish-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Steelfish';
    font-style: normal;
    font-weight: bold;
    src: url(/assets/fonts/Steelfish-Bold.ttf) format('truetype');
}

* {
    font-family: YanoneKaffeesatz;
}

html, body {
  width:  100%;
  height: 100%;
  margin: 0px;
  overflow: hidden;
}

body {
    color: #777;
    background: #1D1F20;
    font-size: 22px;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, .5);
}

#site-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 26px;
    padding: 6px 10px;
    overflow: hidden;
    font-weight: bold;
    background-color: #343436;
    border-bottom: 2px solid #777;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: center;
    @media #{$tablet-retina} {
        height: 16px;
        font-size: 0.72em;
    }
    #site-menu {
        justify-self: start;
    }
    #status {
        justify-self: center;
        #games-on-turn-indicator {
            #games-on-turn-cnt {
                font-family: Play;
                color: #9c9c9c;
            }
            #next-game-on-turn {
                color: #e69900;
                &:hover {
                     color: #ffbb33;
                }
            }
        }
    }
    #user-menu {
        justify-self: end;
        float: right;
    }
    #admin-controls-toggle {
        a {
            cursor: pointer;
            &.on {
                color: #ff3333;
                &:hover {
                     color: #ff8080;
                }
            }
        }
    }
}

#site-content {
    position: fixed;
    top: 42px;
    bottom: 40px;
    @media #{$tablet-retina} {
        top: 30px;
        bottom: 30px;
    }
    left: 0;
    right: 0;
    width: 100%;
    overflow: auto;
    padding: 0 18px 0 0;
    text-align: center;
    .error {
        color: #8f0000;
        background: #ffa7a7;
        text-shadow: 1px 1px 1px rgba(255, 0, 0, .5);
        box-shadow: inset 0 0 8px rgba(0, 0, 0, .5);
        border: 3px dashed #333;
        border-radius: 8px;
        padding: 8px;
    }
}

#site-footer {
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 26px;
    width: 100%;
    padding: 6px 10px;
    overflow: hidden;
    background-color: #343436;
	border-top: 2px solid #777;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media #{$tablet-retina} {
        height: 16px;
        font-size: 0.72em;
    }
    #ext-game-info {
        color: #708090;
        img {
            vertical-align: middle;
            max-height: 28px;
        }
        .helper {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }
    #bgp-info {
        margin-right: 20px;
        #bug-report {
            cursor: pointer;
        }
    }
}

#home {
    text-align: left;
    margin: 30px;
    #intro {
        width: 48%;
        float: left;
        #logo {
            float: left;
            img {
                width: 80px;
                padding: 0 18px 0 0;
            }
        }
        #intro-title {
            color: #cc8800;
            font-size: 1.2em;
            font-weight: bold;
            padding: 2px 12px;
            &:before {
                 font-family: FontAwesome;
                 content: "\f259\00a0\00a0";
            }
            .admin-edit {
                float: right;
                a {
                    color: #ff3333;
                    &:hover {
                         color: #ff8080;
                    }
                }
            }
        }
        section {
            color: #aaa;
            padding: 12px;
        }
    }
    #news {
        width: 48%;
        float: right;
        .item {
            margin: 0 0 30px 0;
            width: 800px;
            .admin-edit, .admin-add {
                a {
                    color: #ff3333;
                    &:hover {
                         color: #ff8080;
                    }
                }
            }
            .admin-edit {
                float: right;
            }
        }
        section {
            a.nav {
                color: #aa9585;
                padding-right: 12px;
                float: right;
                &:hover {
                    color: #c3b4a9;
                }
            }
        }
    }
}

#news {
    text-align: left;
    .item {
        margin: 30px auto;
        width: 1000px;
        header {
            color: #cc8800;
            font-weight: bold;
            padding: 2px 12px;
            overflow: hidden;
            a {
                color: #cc8800;
                &:hover {
                    color: #e69900;
                }
            }
            .title {
                float: left;
                &:before {
                     font-family: FontAwesome;
                     content: "\f1ea\00a0\00a0";
                }
            }
            .timestamp {
                color: #777;
                text-align: right;
                padding-right: 24px;
                float: right;
                &:before {
                     font-family: FontAwesome;
                     content: "\f073\00a0\00a0";
                }
            }
            .admin-edit {
                float: right;
                a {
                    color: #ff3333;
                    &:hover {
                         color: #ff8080;
                    }
                }
            }
        }
        section {
            color: #aaa;
            padding: 6px 12px;
            clear: both;
            overflow: hidden;
            .attention {
                background-color: #333;
                font-weight: bold;
                padding: 8px;
                border: 2px dashed #e69900;
                border-radius: 4px;
            }
            .highlight {
                color: #ff8080;
            }
        }
    }
    .form {
        width: 1000px;
        label {
            padding-bottom: 12px;
            display: inline-block;
            float: left;
        }
        textarea {
            width: 1000px;
            &#headline {
                height: 120px;
            }
            &#details {
                 height: 380px;
            }
        }
        button {
            margin-top: 20px;
            &.confirm {
                background: #ff3333;
            }
        }
        #preview {
            text-align: left;
        }
    }
}

#about, #profile, #prefs, .prefs {
    text-align: left;
    margin: 30px;
    h1 {
        color: #AA9585;
    }
    .prop {
        font-size: 1.5em;
        label {
            color: #e69900;
        }
        .key {
            text-align: right;
            padding: 2px 12px;
        }
        .val {
            padding: 2px 12px;
            color: #aaa;
        }
    }
    .divider {
        margin: 12px;
        border-top: 2px dashed #ccc;
    }
}

#about {
    h1 {
        padding-left: 12px;
    }
    .divider {
        border-top: 2px dashed #777;
    }
    img {
        width: 160px;
    }
}

#prefs, .prefs {
    td {
        vertical-align: top;
    }
    .prop {
        .key, .val {
            padding: 12px;
        }
    }
    .note {
        color: #ffe0b3 !important;
        font-size: 0.7em;
        width: 400px;
        display: inline-block;
    }
    select {
        margin: 0;
        font-size: 1em;
    }
}

#about {
    td {
        vertical-align: top;
    }
    .note {
        color: #777;
        font-size: 0.7em;
    }
    #contact-site {
        &:before {
             font-family: FontAwesome;
             content: "\f007\00a0";
        }
    }
    #contact-twitter {
        &:before {
             font-family: FontAwesome;
             content: "\f099\00a0";
        }
    }
    #contact-discord, #contact-slack {
        display: inline-flex;
        align-items: center;
        .icon {
            fill: #708090;
            width: 1em;
            height: 1em;
        }
        &:hover {
            color: #B0C4DE;
            .icon {
                fill: #B0C4DE;
            }
        }
    }
    #contact-discord {
        .icon {
            width: 1em;
            height: 1em;
            padding-right: 4px;
        }
    }
    #contact-slack {
        .icon {
            width: 1.2em;
            height: 1.2em;
        }
    }
    #contact-email {
        &:before {
             font-family: FontAwesome;
             content: "\f0e0\00a0";
        }
    }
    .contact-email {
        cursor: pointer;
    }
}

#console {
    @media #{$tablet-retina} {
        padding-right: 16px;
    }
    * {
        font-family: Play;
        i.fa {
            font-family: FontAwesome;
        }
    }
    .grid-vertical-2col-left-wider {
        display: grid;
        grid-template-columns: 6fr 4fr;
    }
    .grid-2row-2col {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row: auto auto;
    }
    .flex-horizontal-content-center {
        display: flex;
        justify-content: center;
        .metric {
            flex-grow: 1;
            section {
                text-align: center;
            }
        }
    }
    .flex-horizontal-content-left {
        display: flex;
        justify-content: left;
    }
    .flex-vertical {
        display: flex;
        flex-direction: column;
    }
    .metric, .list {
        padding: 16px;
        margin: 8px;
        border: 2px solid #555;
        border-radius: 8px;
        header {
            font-size: 0.8em;
            @media #{$tablet-retina} {
                font-size: 0.6em;
            }
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            color: #AA9585;
            padding-bottom: 12px;
        }
        section {
            overflow: hidden;
            label {
                font-size: 0.6em;
            }
        }
        label {
            font-size: 0.8em;
            margin: 4px;
            border: 2px solid #555;
            border-radius: 16px;
            display: inline-block;
            overflow: hidden;
            &.caption {
                padding: 8px;
                border: none;
            }
            .key {
                @media #{$tablet-retina} {
                    font-size: 0.54em;
                }
                color: #f7e0b2;
                padding: 8px;
                display: inline-block;
            }
            .value {
                @media #{$tablet-retina} {
                    font-size: 0.54em;
                }
                color: #e69900;
                font-weight: bold;
                padding: 8px 10px 8px 8px;
                border-left: 2px solid #555;
                display: inline-block;
            }
        }
        .game {
            font-size: 0.6em;
            .game-details, .players {
                padding: 0 4px;
            }
            .game-details {
                text-align: right;
                width: 160px;
            }
        }
        .game-link {
            @media #{$tablet-retina} {
                font-size: 0.7em;
            }
        }
        .player {
            @media #{$tablet-retina} {
                font-size: 0.8em;
            }
            padding: 0 2px;
            display: inline-block;
            a {
                color: #555;
                &:before {
                     font-family: FontAwesome;
                     content: "\f2be\00a0";
                }
                &:hover {
                     color: #737373;
                }
            }
        }
        .timestamp {
            font-size: 0.9em;
            @media #{$tablet-retina} {
                font-size: 0.7em;
            }
            color: #555;
            &:not(.no-icon) {
                &:before {
                    font-family: FontAwesome;
                    content: "\00a0\f017\00a0";
                }
            }
        }
        .message {
            @media #{$tablet-retina} {
                font-size: 0.8em;
            }
            padding: 2px 4px 12px 4px;
            overflow-wrap: break-word;
        }
    }
    .metric {
        text-transform: uppercase;
    }
}

#profile {
    table {
      &.stats {
        font-size: 1.1em;
        margin: 30px 12px;
        border-collapse: collapse;
        th, td {
          border: 1px solid #555;
          padding: 12px;
        }
        th {
          background-color: #333;
        }
        td {
          text-align: center;
          &.game-title {
            text-align: right;
          }
          .game-title {
            color: #e69900;
          }
          .wp {
              font-weight: bold;
              color: #888;
              &.awesome {
                  color: #6E9E93;
              }
              &.good {
                  color: #55857A;
              }
              &.poor {
                  color: #9F5757;
              }
          }
        }
      }
    }
    #actions, #admin-actions {
        font-size: 1.2em;
        padding: 2px 12px;
        float: right;
    }
}

#public-stats {
    margin-top: 30px;
    font-size: 0.9em;
    &:before {
        font-family: FontAwesome;
        content: "\f21e";
    }
    .metric {
        margin-left: 8px;
        .name {
            color: #AA9585;
        }
        .value {
            color: #878787;
            font-weight: bold;
        }
    }
}

#game-catalog {
    text-align: left;
    margin-left: 30px;
    font-size: 0.9em;
    header {
        color: #AA9585;
    }
    #games {
        display: flex;
        flex-flow: row wrap;
        gap: 4px;
        .game {
            display: flex;
            flex-direction: column;
            width: 300px;
            border: 1px solid #555;
            padding: 4px;
            text-transform: uppercase;
            .title {
                color: #e69900;
                background: #333;
                font-weight: bold;
                text-align: center;
                padding: 4px;
                border: 1px solid #555;
            }
            .graphics {
                display: flex;
                padding: 4px 0;
                .cover {
                    border: 1px solid #555;
                    img {
                        height: 120px;
                    }
                }
                .publisher {
                    display: flex;
                    flex-direction: column;
                    border: 1px solid #555;
                    margin-left: 4px;
                    width: 100%;
                    header {
                        color: #ccc;
                        background: #555;
                        font-weight: bold;
                        text-align: center;
                        padding: 4px;
                        border-bottom: 1px solid #777;
                    }
                    section {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 8px;
                        img {
                            max-width: 100%;
                            max-height: 60px;
                        }
                    }
                }
            }
            .links {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 4px;
                border: 1px solid #555;
                span {
                    display: inline-block;
                    &:not(:nth-child(1)) {
                        margin-left: 8px;
                    }
                }
            }
            .actions {
                display: flex;
                flex-direction: column;
                padding-top: 4px;
                a {
                    &:not(:nth-child(1)) {
                        margin-top: 4px;
                    }
                    &:hover {
                        .fa {
                            color: #eee;
                        }
                    }
                }
            }
        }
    }
}

#home #game-catalog {
    margin: 0;
    header {
        font-size: 0.8em;
    }
}

#start {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    .games-list-header {
        color: #AA9585;
        padding: 12px;
        text-transform: uppercase;
        font-size: 1.5em;
    }
    #game-join-list {
        order: 1;
        padding: 10px 20px;
        min-width: 300px;
        max-width: 50%;
        vertical-align: top;
        overflow: hidden;
        a {
            color: #b0c4de;
            font-size: 1.2em;
            &:hover {
                 color: #c7d5e7;
            }
        }
        section {
            .game {
                border: 1px solid #555;
                padding: 8px;
                margin: 4px 0 8px 0;
                display: flex;
                &.private {
                    .game-link {
                        &:before {
                            color: #FC7A5E;
                            font-family: FontAwesome;
                            content: "\f070\00a0";
                        }
                    }
                }
                .cover {
                    order: 1;
                    a {
                        display: inline-block;
                        .game-cover {
                            width: 90px;
                            height: 90px;
                            margin-right: 10px;
                            object-fit: cover;
                        }
                    }
                }
                .details {
                    order: 2;
                    text-align: left;
                    font-size: 0.7em;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .top {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        .game-timestamp {
                            color: #958070;
                            padding-left: 20px;
                            text-align: right;
                        }
                    }
                    .middle {
                        display: grid;
                        grid-gap: 4px;
                        .game-setup-options {
                            &:before {
                                font-family: FontAwesome;
                                content: "\f12e\00a0";
                            }
                        }
                        .game-description {
                            color: #ffe0b3;
                            display: inline-block;
                            &:before {
                                font-family: FontAwesome;
                                content: "\f27b\00a0";
                            }
                        }
                    }
                    .bottom {
                        display: grid;
                        grid-gap: 4px;
                        .players {
                            font-size: 0.8em;
                            display: flex;
                            .player {
                                padding-right: 8px;
                                a {
                                    color: #555;
                                    &:before {
                                        font-family: FontAwesome;
                                        content: "\f2be\00a0";
                                    }
                                    &:hover {
                                        color: #737373;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #game-join-list-empty-msg {
                font-size: 1.5em;
            }
        }
    }
    #game-create-list {
        order: 2;
        vertical-align: top;
        padding: 10px 20px;
        max-width: 360px;
        section {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 8px;
            margin: 4px 0;
            .game {
                position: relative;
                text-align: center;
                a {
                    display: inline-block;
                    img.game-cover {
                        width: 90px;
                        height: 90px;
                        object-fit: cover;
                        padding: 8px;
                        border: 1px solid #555;
                    }
                }
                &:hover {
                    &:after {
                        color: #fff;
                        font-family: FontAwesome;
                        font-size: 3em;
                        content: "\f067";
                        opacity: 0.5;
                        cursor: pointer;
                        pointer-events: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
}

#create {
    table {
      td {
        vertical-align: top;
      }
    }
    input, textarea, button {
        width: 100%;
    }
    input[type='checkbox'] {
        width: auto;
    }
    select {
        margin: 4px 0;
    }
    .options-layout-flex-horizontal {
        display: flex;
        flex-direction: row;
        align-items: center;
        .option {
            display: inline-block;
            margin-right: 8px;
        }
    }
    .private-note {
        display: inline-block;
        color: #FC7A5E;
        margin: 8px 24px;
        &:before {
            font-family: FontAwesome;
            content: "\f070\00a0";
        }
    }
}

#play-list, #finished-list {
    display: flex;
    justify-content: center;
    .games-list-header {
        color: #AA9585;
        padding: 12px;
        text-transform: uppercase;
        font-size: 1.5em;
    }
    .games-list {
        padding: 8px;
        a {
            color: #b0c4de;
            font-size: 1.2em;
            &:hover {
                color: #c7d5e7;
            }
            &.on-turn, &.final-checkout {
                color: #e69900;
                &:hover {
                    color: #edb74c;
                }
            }
        }
        .game {
            border: 1px solid #555;
            padding: 8px;
            margin: 8px 0;
            display: flex;
            &.private {
                .game-link {
                    &:before {
                        color: #FC7A5E;
                        font-family: FontAwesome;
                        content: "\f070\00a0";
                    }
                }
            }
            .cover {
                order: 1;
                .game-cover {
                    width: 90px;
                    height: 90px;
                    margin-right: 10px;
                    object-fit: cover;
                }
            }
            .details {
                order: 2;
                text-align: left;
                font-size: 0.7em;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .top {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    .game-timestamp {
                        color: #958070;
                        padding-left: 20px;
                        text-align: right;
                    }
                    .user-game-options {
                        padding: 0 0 0 8px;
                        cursor: pointer;
                        &:before {
                            font-family: FontAwesome;
                            content: "\00a0\f141\00a0";
                        }
                        &:hover {
                            color: #aaa;
                        }
                    }
                }
                .middle {
                    display: grid;
                    grid-gap: 4px;
                    .game-setup-options {
                        &:before {
                            font-family: FontAwesome;
                            content: "\f12e\00a0";
                        }
                    }
                    .game-description {
                        color: #ffe0b3;
                        display: inline-block;
                        &:before {
                          font-family: FontAwesome;
                          content: "\f27b\00a0";
                        }
                    }
                }
                .bottom {
                    display: grid;
                    grid-gap: 4px;
                    .players {
                        font-size: 0.8em;
                        display: flex;
                        .player {
                            padding-right: 8px;
                            a {
                                color: #555;
                                &:before {
                                    font-family: FontAwesome;
                                    content: "\f2be\00a0";
                                }
                                &:hover {
                                    color: #737373;
                                }
                                &.on-turn {
                                    color: #878787;
                                    &:after {
                                        font-family: FontAwesome;
                                        content: "\00a0\f0a5\00a0";
                                    }
                                    &:hover {
                                        color: #a1a1a1;
                                    }
                                }
                                &.winner {
                                    color: #878787;
                                    &:after {
                                        font-family: FontAwesome;
                                        content: "\00a0\f091\00a0";
                                    }
                                    &:hover {
                                        color: #a1a1a1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #game-join-list-empty-msg {
            font-size: 1.5em;
        }
    }
}

#finished-games-history {
    padding: 20px 10px 10px 10px;
}

#finished-list {
    .games-list {
        .game {
            .cover {
                .game-cover {
                    width: 90px;
                    height: 90px;
                    margin-right: 10px;
                    object-fit: cover;
                }
            }
        }
    }
    .page-nav {
        display: flex;
        justify-content: space-between;
    }
}

#create {
    width: 100%;
    text-align: left;
    header {
        padding: 0 30px;
        border-bottom: 2px solid #343436;
        .game-title {
            color: #e69900;
        }
    }
    section {
        padding-top: 30px;
        img.game-cover {
            float: left;
            margin: 0 30px;
        }
        .form {
            padding: 0;
            float: left;
            table {
                width: 100%;
                margin: 0 auto;
                tr {
                    td {
                        padding: 4px 0;
                    }
                }
            }
            input[type=text], input[type=password], input[type=email], select, option, textarea {
                font-family: 'YanoneKaffeesatz';
            }
        }
    }
}

#seating {
    width: 100%;
    text-align: left;
    header {
        padding: 0 30px;
        border-bottom: 2px solid #343436;
        h2 {
            color: #e69900;
        }
        .game-setup-options {
            color: #777;
            &:before {
                font-family: FontAwesome;
                content: "\00a0\f12e\00a0";
            }
        }
    }
    section {
        padding-top: 30px;
        img.game-cover {
            float: left;
            margin: 0 30px;
        }
        &#game-private {
          color: #FC7A5E;
          padding: 4px 30px;
          border-bottom: 2px solid #343436;
          &:before {
            font-family: FontAwesome;
            content: "\f070\00a0";
          }
          #game-link {
            padding-left: 8px;
            color: #708090;
          }
        }
        &#game-description {
          color: #ffe0b3;
          padding: 4px 30px;
          border-bottom: 2px solid #343436;
        }
        #game-details {
            #join-game {
                cursor: pointer;
            }
        }
    }
}

#setup {
    width: 100%;
    .header {
        border-bottom: 2px solid #343436;
        h2 {
            color: #e69900;
        }
        .game-setup-options {
            color: #777;
            &:before {
                font-family: FontAwesome;
                content: "\00a0\f12e\00a0";
            }
        }
    }
    #setup-options {
        font-size: 1.1em;
        padding: 12px 0;
        text-align: center;
        label.label-option, input {
            cursor: pointer;
        }
        .setup-opt-group {
            padding: 24px;
            vertical-align: top;
            display: inline-block;
            .label-option {
                font-weight: bold;
                img {
                    padding: 4px;
                    border: 4px dashed #555;
                }
                &:hover {
                    img {
                        border: 4px dashed #888;
                    }
                }
                &.selected {
                    img {
                        border: 4px dashed #aaa;
                    }
                }
            }
            .label-option-disabled {
                font-weight: bold;
                img {
                    padding: 4px;
                    border: 4px dashed #555;
                }
            }
        }
    }
    #player-colors {
        padding-bottom: 30px;
        border-bottom: 2px solid #343436;
        img {
            height: 80px;
        }
    }
    .player-name {
        margin-right: 18px;
    }
}

#play {
    #play-view {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 42px 1fr;
        @media #{$tablet-retina} {
            grid-template-rows: 30px 1fr;
        }
        #top-panel {
            overflow: hidden;
            background-color: #343436;
            border-bottom: 2px dashed #777;
            padding-bottom: 2px;
            @media #{$tablet-retina} {
                padding-bottom: 0;
            }
            display: flex;
            #controls {
                display: flex;
                align-items: center;
                font-size: 1.2em;
                @media #{$tablet-retina} {
                    font-size: 1.12em;
                }
                padding-left: 6px;
                #side-pane-toggle {
                    padding: 2px 4px;
                    cursor: pointer;
                    display: inline-block;
                    &:before {
                         font-family: FontAwesome;
                         content: "\f152";
                    }
                    &:hover {
                         color: #B0C4DE;
                    }
                    &.on {
                        &:before {
                             font-family: FontAwesome;
                             content: "\f191";
                        }
                    }
                }
                #game-prefs-ctrl {
                    padding: 2px 4px;
                    cursor: pointer;
                    display: inline-block;
                    &:before {
                         font-family: FontAwesome;
                         content: "\f1de";
                    }
                    &:hover {
                         color: #B0C4DE;
                    }
                }
            }
            #cue {
                display: flex;
                align-items: center;
                color: #777;
                @media #{$tablet-retina} {
                    font-size: 0.68em;
                }
                text-shadow: 1px 1px 0 rgba(0, 0, 0, .5);
                .btn-action, .submit {
                    @media #{$tablet-retina} {
                        font-size: 0.9em;
                    }
                    font-weight: bold;
                    text-transform: uppercase;
                }
                img {
                    height: 24px;
                    @media #{$tablet-retina} {
                        height: 18px;
                    }
                    margin: 0 1px;
                    vertical-align: text-bottom;
                }
                span {
                    margin: 2px 4px;
                    float: left;
                    &#game-finished {
                         float: none;
                        .message {
                            color: #e69900;
                            &:before {
                                 color: #ee0000;
                                 font-family: FontAwesome;
                                 content: '\f11e\00a0';
                            }
                        }
                        .username {
                            color: #ffd480;
                            float: none;
                            &:before {
                                 color: #dcdcdc;
                                 font-family: FontAwesome;
                                 content: "\f0a4\00a0\00a0";
                            }
                        }
                    }
                    &#game-cancelled {
                         float: none;
                        .message {
                            color: #e69900;
                            &:before {
                                 color: #ee0000;
                                 font-family: FontAwesome;
                                 content: '\f273\00a0';
                            }
                        }
                    }
                    #cue-confirm, #cue-action, #finish-turn, #cancel-turn {
                        border: 3px dotted #333;
                        border-radius: 8px;
                        padding: 0 8px;
                        margin-left: 4px;
                        cursor: pointer;
                    }
                    #cue-confirm, #cue-action, #finish-turn {
                        color: #ffeecc;
                        background: #cc8800;
                        &:hover {
                            color: #ffe6b3;
                            background: #e69900;
                        }
                    }
                    #cancel-turn {
                        color: #c0c0c0;
                        background: #868686;
                        &:hover {
                            color: #d0d0d0;
                            background: #999999;
                        }
                        &:after {
                             font-family: FontAwesome;
                             content: "\00a0\f0a5";
                        }
                    }
                    #cue-confirm {
                        margin-left: 0;
                        margin-right: 4px;
                        &:before {
                            font-family: FontAwesome;
                            content: "\f0a4\00a0";
                        }
                    }
                    #finish-turn {
                        margin-right: 4px;
                        &:after {
                            font-family: FontAwesome;
                            content: "\00a0\f0a5";
                        }
                    }
                    &.cue {
                        display: flex;
                        flex-shrink: 0;
                        align-items: center;
                        color: #DCDCDC;
                        .cue-items {
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            float: none;
                            &.action-expected {
                                &:before {
                                    font-family: FontAwesome;
                                    content: "\f0a4\00a0";
                                }
                            }
                        }
                    }
                }
                #replay-mode {
                    &:before {
                        font-family: FontAwesome;
                        content: "\f01e\00a0";
                    }
                }
            }
            #alt-scroll {
                font-weight: bold;
                font-size: 1.4em;
                text-shadow: 1px 1px 0 rgba(0, 0, 0, .5);
                margin-top: -3px;
                position: fixed;
                right: 8px;
                .ctrl {
                    color: #708090;
                    margin-left: 8px;
                    cursor: pointer;
                    &:hover {
                         color: #B0C4DE;
                    }
                }
            }
        }
        #main-area {
            position: absolute;
            top: 39px;
            @media #{$tablet-retina} {
                top: 27px;
            }
            bottom: 0;
            left: 0;
            right: 0;
            margin-top: 4px;
            width: 100%;
            overflow: auto;
            &.with-side-pane-narrow {
                #game-layout-content-center {
                    position: relative;
                    left: 240px;
                }
                #game-layout-content-right-side {
                    position: relative;
                    left: 240px;
                    vertical-align: top;
                }
            }
            &.with-side-pane-wide {
                #game-layout-content-center {
                    position: relative;
                    left: 322px;
                }
                #game-layout-content-right-side {
                    position: relative;
                    left: 322px;
                    vertical-align: top;
                }
            }
            #game-layout-content-right-side {
                position: relative;
                left: 2px;
                vertical-align: top;
            }
            td {
                vertical-align: top;
                .player-area {
                    .player-name {
                        padding: 0 0 4px 0;
                        font-size: 1.4em;
                        &.on-turn {
                            color: #DCDCDC;
                            &:after {
                                 font-family: FontAwesome;
                                 content: "\00a0\f0a5";
                            }
                        }
                    }
                }
                &#side-pane {
                    padding: 0 3px 0 1px;
                    margin: 0;
                    position: fixed;
                    &.narrow {
                         width: 238px;
                    }
                    &.wide {
                         width: 320px;
                    }
                    .section-header {
                        color: #aaa;
                        background: #222;
                        font-family: Play;
                        font-weight: bold;
                        text-align: center;
                        padding: 4px 0 2px 4px;
                        height: 20px;
                    }
                    #game-log-section {
                        .section-header {
                            border-bottom: 2px dashed #777;
                            &:before {
                                font-family: FontAwesome;
                                content: "\f1da\00a0";
                            }
                        }
                        #game-log-replay {
                            @media #{$tablet-retina} {
                                font-size: 0.48em;
                            }
                            margin-top: 4px;
                            border-top: 2px dashed #777;
                            display: flex;
                            justify-content: space-between;
                            button, label.btn {
                                flex: 1 1 auto;
                                margin: 5px 2px 2px 2px;
                                outline: none;
                            }
                            label.btn {
                                width: 40%;
                                background-color: #777;
                            }
                            #replay-curr {
                                font-family: Play;
                            }
                            #undo-action {
                                display: inline-block;
                                margin-left: 8px;
                                color: #bebebe;
                                cursor: pointer;
                                &:hover {
                                    color: #ff3333;
                                }
                            }
                        }
                    }
                    #game-notes-section {
                        margin: 4px 0;
                        border-top: 2px dashed #777;
                        .section-header {
                            padding: 6px 0 0 0;
                            cursor: pointer;
                            &:before {
                                font-family: FontAwesome;
                                content: "\f15c\00a0";
                            }
                            &:hover {
                                color: #e69900;
                            }
                        }
                    }
                    #game-chat-section {
                        .section-header {
                            margin: 4px 0;
                            border-top: 2px dashed #777;
                            border-bottom: 2px dashed #777;
                            &:before {
                                font-family: FontAwesome;
                                content: "\f086\00a0";
                            }
                        }
                    }
                    #game-chat {
                        #game-chat-input {
                            color: #e69900;
                            background: #333;
                            caret-color: #888;
                            font-family: Play;
                            font-size: 1em;
                            border: none;
                            padding: 2px 4px;
                            margin: 4px 0;
                            height: 1.5em;
                            width: 100%;
                            overflow: hidden;
                            resize: none;
                            &.exp {
                                 height: 72px;
                            }
                            &::placeholder {
                                color: #555;
                            }
                        }
                        #game-chat-send {
                            font-family: Play;
                            font-size: 0.9em;
                            font-weight: bold;
                            margin: 4px 0;
                            cursor: pointer;
                            width: 100%;
                        }
                        #game-chat-log {
                            margin-top: 4px;
                            .entry {
                                width: 100%;
                                header {
                                    color: #ccc;
                                    background: #555;
                                    padding: 2px 4px;
                                    margin-top: 2px;
                                    overflow: hidden;
                                    .player {
                                        &:before {
                                             font-family: FontAwesome;
                                             content: "\f2be\00a0";
                                        }
                                    }
                                    .admin {
                                        color: #e69900;
                                        &:before {
                                             font-family: FontAwesome;
                                             content: "\f2bd\00a0";
                                        }
                                    }
                                    .timestamp {
                                        float: right;
                                        &:before {
                                             font-family: FontAwesome;
                                             content: "\00a0\f017\00a0";
                                        }
                                    }
                                }
                                .message {
                                    font-family: Play;
                                    padding: 4px;
                                    overflow-wrap: break-word;
                                }
                            }
                        }
                    }
                }
                &#game-layout-content-right-side {
                    #right-side-pane {
                        position: fixed;
                    }
                }
            }
        }
    }
    .game-setup-options {
        &:before {
             font-family: FontAwesome;
             content: "\f12e\00a0";
        }
    }
}

#game-log {
    .entry {
        &.confirm {
            display: none;
        }
    }
    img {
        height: 18px;
        margin: 0 1px;
        vertical-align: text-bottom;
    }
}

div[aria-describedby='game-notes-dialog'] {
    .ui-dialog-title {
        color: #aaa;
    }
    .ui-dialog-titlebar-close {
        display: none;
        visibility: hidden;
    }
}

#game-notes-dialog {
    #game-notes {
        color: #aaa;
        background: #333;
        caret-color: #888;
        font-family: Play;
        font-size: 0.8em;
        border: none;
        padding: 2px 4px;
        margin: 4px 0;
        width: 100%;
        height: 300px;
        resize: none;
    }
    div {
        padding: 8px;
    }
    button {
        font-family: Play;
        font-weight: bold;
        cursor: pointer;
        width: 49%;
        &:disabled {
            color: #aaa;
            cursor: default;
        }
    }
    #game-notes-cancel-ctrl {
        float: right;
    }
}

#game-prefs-dialog {
    padding: 20px;
}

.ui-dialog-content {
    .important {
        clear: both;
        color: #cf8900;
        font-size: 0.8em;
        padding: 16px;
        margin: 8px;
        border: 2px dashed #cf8900;
        border-radius: 8px;
        .message {
            &:before {
                font-family: FontAwesome;
                content: '\f06a\00a0';
            }
        }
    }
}

.games-list.empty {
    font-size: 1.8em;
    vertical-align: top;
}

.hide {
  display: none;
}

.dialog-trigger {
  cursor: pointer;
}

.dialog {
    table {
        margin: 0 auto;
        td {
            text-align: center;
            &.ta-r {
                text-align: right;
            }
            &.ta-l {
                text-align: left;
            }
        }
    }
}

#error-dialog {
    padding: 30px;
    #error-dialog-content {
        width: 100%;
        display: grid;
        #error-dialog-message {
            color: #aa0000;
            margin: auto;
        }
    }
}

.copy-to-clipboard-ctrl:before {
  font-family: FontAwesome;
  font-weight: bold;
  content: "\f0c5\00a0";
  cursor: pointer;
}

.text-align-left {
    text-align: left !important;
}

.text-align-right {
    text-align: right !important;
}

.text-align-center {
    text-align: center !important;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.clear:after {
    clear: both;
    content: " ";
    display: block;
    height: 0;
    line-height: 0;
    visibility: hidden;
}

a {
    color: #708090;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, .5);
    text-decoration: none;
    outline: none;
    &:hover {
        color: #B0C4DE;
    }
}

button, a.btn, label.btn {
    background-color: #708090;
    color: #eee;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, .5);
    border: 1px solid #aaa;
    border-radius: 2px;
    padding: 4px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
    outline: none;
}
button, a.btn {
    &:hover {
        background-color: #7b8c9d;
    }
    &.alt {
        background-color: #880000;
        &:hover {
            background-color: #990000;
        }
    }
    &.cancel {
        background-color: #777;
        &:hover {
            background-color: #848484;
        }
    }
    &:disabled {
        background-color: #777;
        color: #aaa;
        cursor: default;
        img {
            opacity: 0.5;
        }
    }
    &:active {
        box-shadow: inset 0 0 2px #eee;
    }
}

a.img-view-toggle {
    .fa {
        padding-left: 4px;
    }
    &:hover {
        .fa {
            color: #e69900;
        }
    }
}

form {
    fieldset {
        border: 1px solid #777;
        border-radius: 2px;
        p {
            margin: 8px 0;
        }
    }
    input[type=text], input[type=password], input[type=email], select, option, textarea {
        font-family: Play;
        width: 100%;
        border: none;
        padding: 2px 6px;
        font-size: 1em;
    }
}

.form {
    width: 400px;
    padding: 0 32px 32px 32px;
    margin: 0 auto;
    text-align: center;
    input, button {
        width: 100%;
    }
    input, button {
        font-size: 1.4em;
    }
    .attention {
        color: #cc8800;
        .highlight {
            color: #FC7A5E;
            font-weight: bold;
        }
    }
    &.signup, &.login, &.reset {
        width: 800px;
        input, button {
            width: 300px;
        }
        .g-recaptcha {
            div {
                width: 800px;
            }
        }
    }
}

.form.admin {
    input, select, textarea {
        font-family: Play;
    }
}

#user-game-options-dialog {
    padding: 20px;
    text-align: left;
    #user-game-options-dialog-request-cancellation-header {
        color: #aaa;
        margin: 0 8px;
    }
    #user-game-options-dialog-options {
        .option {
            display: grid;
            grid-gap: 20px 0;
            .actions {
                margin: 0 8px;
            }
        }
    }
    #user-game-options-dialog-cancel-req-players {
        display: grid;
        grid-gap: 8px 0;
        font-size: 0.8em;
        padding: 16px;
        margin: 0 8px;
        border: 2px dashed #555;
        border-radius: 8px;
        .players {
            .player {
                padding: 0 12px 0 0;
                color: #555;
                &:before {
                    font-family: FontAwesome;
                    content: "\f2be\00a0";
                }
            }
        }
    }
}

/* ======================== */
/*        Components        */
/* ======================== */

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #777;
    border-radius: 34px;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #eee;
    border-radius: 50%;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2eb82e;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2eb82e;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
  margin-bottom: 8px;
  background-color: #708090;
  color: #dcdcdc;
  text-align: center;
  padding: 4px 4px 6px 4px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #708090 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* ======================== */
/*         Effects          */
/* ======================== */

.blur {
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    filter: blur(3px);
}

.blink-3-hl {
    animation: anim-blink-hl 1s linear 3;
}

.blink-10-hl {
    animation: anim-blink-hl 1s linear 10;
}

.blink-warning-hl {
    animation: anim-blink-warning-hl 1s linear 10;
}

@keyframes anim-blink-hl {
    0% { color: #e69900; }
    50% { opacity: 0; color: #ffbb33; }
    100% { color: #e69900; }
}

@keyframes anim-blink-warning-hl {
    0% { color: #ff3a0b; }
    50% { opacity: 0; color: #fc7a5e; }
    100% { color: #ff3a0b; }
}

.blink-inf {
    animation: anim-blink-inf 1s linear infinite;
    &:hover {
        animation: none;
    }
}

@keyframes anim-blink-inf {
    50% { opacity: 0; }
}

.rotate-y-90-0 {
    perspective: 1000px;
    img {
        animation: anim-rotate-y-90-0 1s linear 1;
    }
}

@keyframes anim-rotate-y-90-0 {
    0% { transform: rotateY(90deg); }
    50% { transform: rotateY(45deg); }
    100% { transform: none; }
}

/* ======================== */
/* jQuery/UI Customizations */
/* ======================== */

.chocolat-overlay {
  background-color: #111;
  opacity: 0.95;
}

.chocolat-bottom {
    display: none;
}

.ui-widget {
    * {
        font-family: Play !important;
    }
    i.fa {
        font-family: FontAwesome !important;
    }
}

.ui-dialog {
    position: fixed;
    outline: none;
    .slimScrollDiv {
        position: absolute !important;
    }
}

.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.ui-widget-content {
    border: none;
}

.ui-front {
    z-index: 100;
}

.ui-dialog-titlebar {
    text-align: left;
    * {
        font-family: YanoneKaffeesatz !important;
    }
    .ui-dialog-title {
        padding-left: 8px;
    }
    .ui-button {
        color: #454545 !important;
        float: right !important;
        font-size: initial !important;
        @media #{$tablet-retina} {
            font-size: 0.44em !important;
            margin: 0 !important;
        }
    }
}

.ui-dialog-content {
    .note {
      color: #777;
    }
    .warning {
        color: #cf8900;
        &:before {
             font-family: FontAwesome;
             content: "\00a0\f071\00a0";
        }
    }
    .note, .warning {
        text-shadow: 1px 1px 0 rgba(0, 0, 0, .5);
    }
}

.preloader {
    background: #1d1f20;
}
